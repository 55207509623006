html {
  position: relative;
  min-height: 100%;
}

body {
  background-color: rgba(247, 247, 255);
  /* background-color: #18191a; */
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: large;
  margin-bottom: 60px; /* Margin bottom by footer height */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box; }

input,
button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none; }

.AppWrapper {
  width: 500px;
  height: 400px;
  position: relative; }

@-webkit-keyframes fade {
  from {
    opacity: 0;
    -webkit-transform: scale3D(0.95, 0.95, 0.95);
            transform: scale3D(0.95, 0.95, 0.95); }
  to {
    opacity: 1;
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1); } }

@keyframes fade {
  from {
    opacity: 0;
    -webkit-transform: scale3D(0.95, 0.95, 0.95);
            transform: scale3D(0.95, 0.95, 0.95); }
  to {
    opacity: 1;
    -webkit-transform: scale3D(1, 1, 1);
            transform: scale3D(1, 1, 1); } }

.Form {
  margin-top: 15px;
  -webkit-animation: fade 200ms ease-out;
          animation: fade 200ms ease-out; }

.FormGroup {
  margin: 0 15px 20px;
  padding: 0;
  border-style: none;
  background-color: #607D8B;
  will-change: opacity, transform;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #455A64;
  border-radius: 4px; }

.FormRow {
  display: flex;
  align-items: center;
  margin-left: 15px;
  border-top: 1px solid #B0BEC5; }

.FormRow:first-child {
  border-top: none; }

.FormRowLabel {
  width: 15%;
  min-width: 70px;
  padding: 11px 0;
  color: #E1F5FE;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

@-webkit-keyframes void-animation-out {
  0%,
  to {
    opacity: 1; } }

@keyframes void-animation-out {
  0%,
  to {
    opacity: 1; } }

.FormRowInput:-webkit-autofill {
  -webkit-text-fill-color: #F8BBD0;
  /* Hack to hide the default webkit autofill */
  -webkit-transition: background-color 100000000s;
  transition: background-color 100000000s;
  -webkit-animation: 1ms void-animation-out;
          animation: 1ms void-animation-out; }

.FormRowInput {
  font-size: 16px;
  width: 100%;
  padding: 11px 15px 11px 0;
  color: #fff;
  background-color: transparent;
  -webkit-animation: 1ms void-animation-out;
          animation: 1ms void-animation-out; }

.FormRowInput::-webkit-input-placeholder {
  color: #80D8FF; }

.FormRowInput::-ms-input-placeholder {
  color: #80D8FF; }

.FormRowInput::placeholder {
  color: #80D8FF; }

.StripeElement--webkit-autofill {
  background: transparent !important; }

.StripeElement {
  width: 100%;
  padding: 15px 15px 15px 0; }

.SubmitButton {
  display: block;
  font-size: 16px;
  width: calc(100% - 30px);
  height: 50px;
  margin: 40px 15px 0;
  background-color: #2196F3;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #1976D2;
  border-radius: 4px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
  -webkit-transition: all 100ms ease-in-out;
  transition: all 100ms ease-in-out;
  will-change: transform, background-color, box-shadow; }

.SubmitButton:active {
  background-color: #0D47A1;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #0D47A1;
  -webkit-transform: scale(0.99);
          transform: scale(0.99); }

.SubmitButton:hover {
  background-color: #1E88E5;
  box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #0D47A1;
  -webkit-transform: scale(0.99);
          transform: scale(0.99); }

.SubmitButton.SubmitButton--error {
  -webkit-transform: translateY(15px);
          transform: translateY(15px); }

.SubmitButton.SubmitButton--error:active {
  -webkit-transform: scale(0.99) translateY(15px);
          transform: scale(0.99) translateY(15px); }

.SubmitButton:disabled {
  opacity: 0.5;
  cursor: default;
  background-color: #7795f8;
  box-shadow: none; }

.ErrorMessage {
  color: #fff;
  position: absolute;
  display: flex;
  justify-content: center;
  padding: 0 15px;
  font-size: 13px;
  margin-top: 0px;
  width: 100%;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
  opacity: 0;
  -webkit-animation: fade 150ms ease-out;
          animation: fade 150ms ease-out;
  -webkit-animation-delay: 50ms;
          animation-delay: 50ms;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  will-change: opacity, transform; }

.ErrorMessage svg {
  margin-right: 10px; }

.Result {
  margin-top: 50px;
  text-align: center;
  -webkit-animation: fade 200ms ease-out;
          animation: fade 200ms ease-out; }

.ResultTitle {
  color: #fff;
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 17px;
  text-align: center; }

.ResultMessage {
  color: #9cdbff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 25px;
  line-height: 1.6em;
  text-align: center; }

.ResetButton {
  border: 0;
  cursor: pointer;
  background: transparent; }

.bg-prim {
  background-color: #020475;
  color: #ffffff; }

.bg-prim-light {
  background-color: #492fa5;
  color: #ffffff; }

.bg-prim-dark {
  background-color: #000049;
  color: #ffffff; }

.bg-sec {
  background-color: #24a4d1;
  color: #000000; }

.bg-sec-light {
  background-color: #69d5ff;
  color: #000000; }

.bg-sec-dark {
  background-color: #0075a0;
  color: #ffffff; }

.color-prim-light {
  color: #492fa5; }

.color-prim {
  color: #020475; }

.color-prim-dark {
  color: #000049; }

.color-secondary-light {
  color: #69d5ff; }

.color-secondary {
  color: #24a4d1; }

.color-secondary-dark {
  color: #0075a0; }

.color-err-light {
  color: #f8523e; }

.color-err {
  color: #be1414; }

.color-err-dark {
  color: #860000; }

.color-comp-light {
  color: #6bffdb; }

.color-comp {
  color: #24d1a9; }

.color-comp-dark {
  color: #009f7a; }

.color-analog-light {
  color: #ff8250; }

.color-analog {
  color: #d15224; }

.color-analog-dark {
  color: #992000; }

.btn-prim {
  background-color: #020475;
  color: white;
  font-weight: bold; }

.btn-prim:hover {
  background-color: #492fa5;
  color: white;
  font-weight: bold; }

.btn-prim:active {
  background-color: #492fa5;
  color: white;
  font-weight: bold; }

.btn-prim:focus {
  background-color: #492fa5;
  color: white;
  font-weight: bold; }

.btn-sec {
  background-color: #24a4d1;
  color: black;
  font-weight: bold; }

.btn-sec:hover {
  background-color: #0075a0;
  color: white;
  font-weight: bold; }

.btn-sec:active {
  background-color: #0075a0;
  color: white;
  font-weight: bold; }

.btn-sec:focus {
  background-color: #0075a0;
  color: white;
  font-weight: bold; }

.btn-err {
  background-color: #be1414;
  color: white;
  font-weight: bold; }

.btn-err:hover {
  background-color: #860000;
  color: white;
  font-weight: bold; }

.btn-err:active {
  background-color: #860000;
  color: white;
  font-weight: bold; }

.btn-err:focus {
  background-color: #860000;
  color: white;
  font-weight: bold; }

.btn-comp {
  background-color: #24d1a9;
  color: black;
  font-weight: bold; }

.btn-comp:hover {
  background-color: #009f7a;
  color: black;
  font-weight: bold; }

.btn-comp:active {
  background-color: #009f7a;
  color: black;
  font-weight: bold; }

.btn-comp:focus {
  background-color: #009f7a;
  color: black;
  font-weight: bold; }

.btn-analog {
  background-color: #d15224;
  color: black;
  font-weight: bold; }

.btn-analog:hover {
  background-color: #992000;
  color: white;
  font-weight: bold; }

.btn-analog:active {
  background-color: #992000;
  color: white;
  font-weight: bold; }

.btn-analog:focus {
  background-color: #992000;
  color: white;
  font-weight: bold; }

.navLogo {
  background: #FFFFFFCC;
  border-radius: 10px; }

.navLogo:hover {
  background: #FFFFFFDD;
  border-radius: 10px; }

.navLogo:active {
  background: #FFFFFF;
  border-radius: 10px; }

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: #492fa5;
  color: white;
  font-weight: bold; }

.text-bold {
  font-weight: bold; }

.tab {
  margin-left: 50px; }

.fade-enter p {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.fade-enter-active p {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.fade-exit p {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.fade-exit-active p {
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.fade-enter-active p,
.fade-exit-active p {
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, transform 500ms;
  transition: opacity 500ms, transform 500ms, -webkit-transform 500ms; }

.fade-enter .btn {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.fade-enter-active .btn {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.fade-exit .btn {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.fade-exit-active .btn {
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.fade-enter-active .btn,
.fade-exit-active .btn {
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, transform 500ms;
  transition: opacity 500ms, transform 500ms, -webkit-transform 500ms; }

.fade-enter .form-row {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.fade-enter-active .form-row {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.fade-exit .form-row {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.fade-exit-active .form-row {
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.fade-enter-active .form-row,
.fade-exit-active .form-row {
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, transform 500ms;
  transition: opacity 500ms, transform 500ms, -webkit-transform 500ms; }

.fade-enter table {
  opacity: 0;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }

.fade-enter-active table {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.fade-exit table {
  opacity: 1;
  -webkit-transform: translateX(0%);
          transform: translateX(0%); }

.fade-exit-active table {
  opacity: 0;
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }

.fade-enter-active table,
.fade-exit-active table {
  -webkit-transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, -webkit-transform 500ms;
  transition: opacity 500ms, transform 500ms;
  transition: opacity 500ms, transform 500ms, -webkit-transform 500ms; }

.hover-card {
  box-shadow: 2px 2px 2px 0px #aaaaaa; }

.hover-card:hover {
  box-shadow: 2px 2px 10px 0px #aaaaaa;
  -webkit-transition: 250ms;
  transition: 250ms; }

.videoPlayer {
  width: 100% !important;
  height: auto !important; }

.center {
  margin-left: auto;
  margin-right: auto; }

.hr {
  border-color: ghostwhite; }

.foot-color {
  color: ghostwhite; }

html, body {
  height: 100%; }

body {
  display: flex;
  flex-direction: column; }

.content {
  min-height: calc(100vh - 189px); }

.footer {
  height: 100%;
  background-color: #101020;
  line-height: '25px';
  color: white;
  text-align: center; }

.linkFooter :hover {
  color: #009f7a; }

.btn-sec:not(:disabled):not(.disabled).active, .btn-sec:not(:disabled):not(.disabled):active, .show > .btn-sec.dropdown-toggle {
  background-color: #0075a0;
  color: white;
  font-weight: bold;
  border-color: #0075a0; }

.tab-button {
  background: none;
  border-bottom: solid #FFFFFF00 5px;
  -webkit-transition: linear 500ms;
  transition: linear 500ms; }

.tab-button-selected {
  background: none;
  border-bottom: solid #009f7a 5px;
  -webkit-transition: linear 200ms;
  transition: linear 200ms; }

.tab-button:hover {
  background: none;
  border-bottom: solid #24d1a9 5px;
  -webkit-transition: linear 200ms;
  transition: linear 200ms; }

.tab-btn {
  background: none;
  outline: 0 !important; }

.tab-btn:focus {
  background: none;
  outline: 0 !important; }

.tab-btn:active {
  background: none;
  outline: 0 !important; }

.cardH {
  background-color: #92beb499; }

.cardB {
  background-color: #28a74577; }

.cardF {
  background-color: #92beb499; }

.form {
  justify-content: center; }

.m05 {
  margin-bottom: 6px; }

#nprogress {
  -webkit-transition: linear 500ms !important;
  transition: linear 500ms !important; }

#nprogress .bar {
  -webkit-transition: linear 500ms !important;
  transition: linear 500ms !important;
  border-radius: 10px !important;
  background: #24d1a9 !important;
  height: 4px !important; }

#nprogress .peg {
  box-shadow: 0 0 10px #005f3a, 0 0 5px #005f3a !important; }

#nprogress .spinner-icon {
  width: 22px !important;
  height: 22px !important;
  border: solid 3px transparent !important;
  border-top-color: #24d1a9 !important;
  border-left-color: #24d1a9 !important; }

.italic {
  font-style: italic; }

.email-avisoP {
  font-weight: 700;
  color: #020475;
  text-decoration: underline; }

.letras-comexcol {
  font-size: 93.3333px;
  fill: #ffffff;
  fill-opacity: 1;
  stroke: #ffffff;
  stroke-width: 1.32844;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 1; }

.g-path-comexcol-1 {
  font-style: normal;
  font-feature-settings: normal;
  font-variant: normal;
  font-weight: normal;
  font-stretch: normal;
  font-size: 96px;
  line-height: 1.25;
  font-family: 'Felix Titling';
  -inkscape-font-specification: 'Felix Titling';
  letter-spacing: 0px;
  word-spacing: 0px;
  fill: #ffffff;
  fill-opacity: 1;
  stroke: #ffffff;
  stroke-width: 1.32844;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 1; }

.g-path-comexcol-2 {
  fill: #ffffff;
  fill-opacity: 1;
  stroke: none;
  stroke-opacity: 1; }

.logo-comexcol {
  opacity: 1;
  fill: #ffffff;
  fill-opacity: 1;
  stroke: none;
  stroke-width: 0.265;
  stroke-linecap: butt;
  stroke-linejoin: round;
  stroke-miterlimit: 4;
  stroke-dasharray: none;
  stroke-opacity: 1; }

.form-admin {
  display: block !important;
  width: 100% !important; }

.dropright .dropdown-menu {
  max-height: 500px;
  overflow-y: scroll; }

