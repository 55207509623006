// Primary Color
$primary-light: #492fa5;
$primary: #020475;   // Navbar
$primary-dark: #000049;
// Secondary Color
$secondary-light: #69d5ff;
$secondary: #24a4d1;
$secondary-dark: #0075a0;
// Errors, Danger, Cancel
$error-light: #f8523e;
$error: #be1414;
$error-dark: #860000;
// Complementary
$comp-light: #6bffdb;
$comp: #24d1a9;
$comp-dark: #009f7a;
// Analogous
$analog-light: #ff8250;
$analog: #d15224;
$analog-dark: #992000;

// Other Colors
$sonicSilver: #787878;
$snow: #fffcf9;
$davysGray: #595457;   
$burgundy: #710627;
$ghostWhite: #f7f7ff;   // Background
$silver: #b8bfc1;
$turkishRose: #b17789;
$purpleTaupe: #35303d;  //Footer


//---------- Base Styles -----------------
// Background Primary Color & Variations
.bg-prim {
    background-color: $primary;
    color: #ffffff;
}
// Background Primary-Light Color
.bg-prim-light {
    background-color: $primary-light;
    color: #ffffff;
}
// Background Primary-Dark Color
.bg-prim-dark {
    background-color: $primary-dark;
    color: #ffffff;
}
// Background Secondary Color & Variations
.bg-sec {
    background-color: $secondary;
    color: #000000;
}
// Background Secondary-Light Color
.bg-sec-light {
    background-color: $secondary-light;
    color: #000000;
}
// Background Secondary-Dark Color
.bg-sec-dark {
    background-color: $secondary-dark;
    color: #ffffff;
}

.color-prim-light {
    color: $primary-light
}
.color-prim {
    color: $primary
}
.color-prim-dark {
    color: $primary-dark
}
.color-secondary-light {
    color: $secondary-light
}
.color-secondary {
    color: $secondary
}
.color-secondary-dark {
    color: $secondary-dark
}
.color-err-light {
    color: $error-light
}
.color-err {
    color: $error
}
.color-err-dark {
    color: $error-dark
}
.color-comp-light {
    color: $comp-light
}
.color-comp {
    color: $comp
}
.color-comp-dark {
    color: $comp-dark
}
.color-analog-light {
    color: $analog-light
}
.color-analog {
    color: $analog
}
.color-analog-dark {
    color: $analog-dark
}

// Button Primary color
.btn-prim {
    background-color: $primary;
    color: white;
    font-weight: bold;
}
.btn-prim:hover {
    background-color: $primary-light;
    color: white;
    font-weight: bold;
}
.btn-prim:active {
    background-color: $primary-light;
    color: white;
    font-weight: bold;
}
.btn-prim:focus {
    background-color: $primary-light;
    color: white;
    font-weight: bold;
}
// Button Secondary Color
.btn-sec {
    background-color: $secondary;
    color: black;
    font-weight: bold;
}
.btn-sec:hover {
    background-color: $secondary-dark;
    color: white;
    font-weight: bold;
}
.btn-sec:active {
    background-color: $secondary-dark;
    color: white;
    font-weight: bold;
}
.btn-sec:focus {
    background-color: $secondary-dark;
    color: white;
    font-weight: bold;
}
// Button error Color
.btn-err {
    background-color: $error;
    color: white;
    font-weight: bold;
}
.btn-err:hover {
    background-color: $error-dark;
    color: white;
    font-weight: bold;
}
.btn-err:active {
    background-color: $error-dark;
    color: white;
    font-weight: bold;
}
.btn-err:focus {
    background-color: $error-dark;
    color: white;
    font-weight: bold;
}
// Button Complementary Color
.btn-comp {
    background-color: $comp;
    color: black;
    font-weight: bold;
}
.btn-comp:hover {
    background-color: $comp-dark;
    color: black;
    font-weight: bold;
}
.btn-comp:active {
    background-color: $comp-dark;
    color: black;
    font-weight: bold;
}
.btn-comp:focus {
    background-color: $comp-dark;
    color: black;
    font-weight: bold;
}
// Button Analogous Color
.btn-analog {
    background-color: $analog;
    color: black;
    font-weight: bold;
}
.btn-analog:hover {
    background-color: $analog-dark;
    color: white;
    font-weight: bold;
}
.btn-analog:active {
    background-color: $analog-dark;
    color: white;
    font-weight: bold;
}
.btn-analog:focus {
    background-color: $analog-dark;
    color: white;
    font-weight: bold;
}
//---------- Base Styles -----------------



//---------- Custom Styles for specific elements -----------------

.navLogo {
    background: #FFFFFFCC;
    // box-shadow: 0px 0px 5px 2px #dddddd;
    border-radius: 10px;
}
.navLogo:hover {
    background: #FFFFFFDD;
    // box-shadow: 0px 0px 5px 3px #dddddd;
    border-radius: 10px;
}
.navLogo:active {
    background: #FFFFFF;
    // box-shadow: 0px 0px 5px 5px #dddddd;
    border-radius: 10px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: $primary-light;
    color: white;
    font-weight: bold;
}

.text-bold {
    font-weight: bold;
}

.tab {
    margin-left: 50px;
}


// Transitions
.fade-enter p {
    opacity: 0;
    transform: translateX(-100%);
}
.fade-enter-active p {
    opacity: 1;
transform: translateX(0%);
}
.fade-exit p {
    opacity: 1;
transform: translateX(0%);
}
.fade-exit-active p {
    opacity: 0;
transform: translateX(100%);
}
.fade-enter-active p ,
.fade-exit-active p {
transition: opacity 500ms, transform 500ms;
}

.fade-enter .btn {
    opacity: 0;
    transform: translateX(-100%);
}
.fade-enter-active .btn {
opacity: 1;
transform: translateX(0%);
}
.fade-exit .btn {
opacity: 1;
transform: translateX(0%);
}
.fade-exit-active .btn {
opacity: 0;
transform: translateX(100%);
}
.fade-enter-active .btn,
.fade-exit-active .btn {
transition: opacity 500ms, transform 500ms;
}

.fade-enter .form-row {
    opacity: 0;
    transform: translateX(-100%);
}
.fade-enter-active .form-row {
opacity: 1;
transform: translateX(0%);
}
.fade-exit .form-row {
opacity: 1;
transform: translateX(0%);
}
.fade-exit-active .form-row {
opacity: 0;
transform: translateX(100%);
}
.fade-enter-active .form-row,
.fade-exit-active .form-row {
transition: opacity 500ms, transform 500ms;
}

.fade-enter table {
    opacity: 0;
    transform: translateX(-100%);
}
.fade-enter-active table {
    opacity: 1;
transform: translateX(0%);
}
.fade-exit table {
    opacity: 1;
transform: translateX(0%);
}
.fade-exit-active table {
    opacity: 0;
transform: translateX(100%);
}
.fade-enter-active table ,
.fade-exit-active table {
transition: opacity 500ms, transform 500ms;
}

.hover-card {
    box-shadow: 2px 2px 2px 0px #aaaaaa;
}

.hover-card:hover {
    box-shadow: 2px 2px 10px 0px #aaaaaa;
    transition: 250ms;
}

.videoPlayer {
    width: 100%!important;
    height: auto!important;
}

.center{
    margin-left: auto;
    margin-right: auto;
  }
.hr {
    border-color: ghostwhite;
}

.foot-color{
    color: ghostwhite;
}

// .footer {
//     position: absolute;
//     left: 0;
//     bottom: 0;
//     width: 100%;
//     background-color: rgba(49, 44, 57, 1);
//     line-height: '25px';
//     color: white;
//     text-align: center;
//     margin-bottom: -250px;
//  }

 html, body {
    height: 100%;
  }
  
  body {
    display: flex;
    flex-direction: column;
  }

  .content {
    min-height: calc(100vh - 189px);
  }

  .footer {
    height: 100%;
    // background-color: rgba(49, 44, 57, 1);
    background-color: #101020;
    line-height: '25px';
    color: white;
    text-align: center;
  }

  .linkFooter :hover {
      color: $comp-dark;
  }

  .btn-sec:not(:disabled):not(.disabled).active, .btn-sec:not(:disabled):not(.disabled):active, .show>.btn-sec.dropdown-toggle {
    background-color: $secondary-dark;
    color: white;
    font-weight: bold;
    border-color: $secondary-dark;
  }

.tab-button {
    background: none;
    border-bottom: solid #FFFFFF00 5px;
    transition: linear 500ms;
}
.tab-button-selected {
    background: none;
    border-bottom: solid $comp-dark 5px;
    transition: linear 200ms;
}
.tab-button:hover {
    background: none;
    border-bottom: solid $comp 5px;
    transition: linear 200ms;
}

.tab-btn {
    background: none;
    outline:0 !important; 
}
.tab-btn:focus {
    background: none;
    outline:0 !important; 
}
.tab-btn:active {
    background: none;
    outline:0 !important; 
}
.cardH {
    background-color: #92beb499 ;
}
.cardB {
    background-color: #28a74577 ;
}
.cardF {
    background-color: #92beb499;
}
.form {
    justify-content: center;
}

.m05 {
    margin-bottom: 6px;
}
// axios-progress-bar custom styles
#nprogress {
    transition: linear 500ms !important;
}

#nprogress .bar {
    transition: linear 500ms !important;
    border-radius: 10px !important;
    background: #24d1a9 !important;
    height: 4px !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px #005f3a, 0 0 5px #005f3a !important;
}

#nprogress .spinner-icon {
    width: 22px !important;
    height: 22px !important;
    border: solid 3px transparent !important;
    border-top-color: #24d1a9 !important;
    border-left-color: #24d1a9 !important;
}

.italic {
    font-style: italic;
}

.email-avisoP {
    font-weight: 700;
    color: $primary;
    text-decoration: underline;
}

// svg comexcol logo navbar
.letras-comexcol {
    font-size:93.3333px;fill:#ffffff;
    fill-opacity:1;
    stroke:#ffffff;
    stroke-width:1.32844;
    stroke-miterlimit:4;
    stroke-dasharray:none;
    stroke-opacity:1;
}

.g-path-comexcol-1 {
    font-style:normal;
    font-variant:normal;
    font-weight:normal;
    font-stretch:normal;
    font-size:96px;
    line-height:1.25;
    font-family:'Felix Titling';
    -inkscape-font-specification:'Felix Titling';
    letter-spacing:0px;
    word-spacing:0px;
    fill:#ffffff;
    fill-opacity:1;
    stroke:#ffffff;
    stroke-width:1.32844;
    stroke-miterlimit:4;
    stroke-dasharray:none;
    stroke-opacity:1;
}

.g-path-comexcol-2 {
    fill:#ffffff;
    fill-opacity:1;
    stroke:none;
    stroke-opacity:1;
}

.logo-comexcol {
    opacity:1;
    fill:#ffffff;
    fill-opacity:1;
    stroke:none;
    stroke-width:0.265;
    stroke-linecap:butt;
    stroke-linejoin:round;
    stroke-miterlimit:4;
    stroke-dasharray:none;
    stroke-opacity:1;
}

.form-admin {
    display: block !important;
    width: 100% !important;
}

.dropright .dropdown-menu {
    max-height: 500px;
    overflow-y: scroll;
}
// ---------- Custom Styles for specific elements -----------------